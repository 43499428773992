/// <reference types="vite/client" />
import { z } from 'zod';

import { logError } from './lib/errorLogging';

declare global {
  interface ImportMetaEnv extends Environment {}
}

const envSchema = z.object({
  VITE_STORAGE_NAME: z.string(),
  VITE_API_URL: z.string().url().default('http://localhost:5274'),
  VITE_POSTHOG_API_KEY: z.string(),
  VITE_AUTH0_DOMAIN: z.string(),
  VITE_AUTH0_CLIENT_ID: z.string(),
  VITE_AUTH0_AUDIENCE: z.string().url(),
  VITE_ENV: z
    .enum(['development', 'staging', 'production'])
    .default('development'),
  IS_RUNNING_IN_LOCALHOST: z.boolean().default(false),
  BASE_URL_FOR_PUBLIC_LINKS: z.string().url().optional(),
  ENABLE_DEVTOOLS: z.boolean().optional().default(false),
  VITE_IS_STAGING_BUILD_FOR_DESKTOP: z.coerce.boolean().default(false),
  VITE_ENABLE_GOOGLE_SIGNIN: z.coerce.boolean().default(false),
});

type Environment = z.infer<typeof envSchema>;

function validateEnv(): Environment {
  try {
    const rawEnv = import.meta.env;

    // Parse the env first (without BASE_URL_FOR_PUBLIC_LINKS)
    const partiallyParsedEnv = envSchema.partial().parse({
      ...rawEnv,
      IS_RUNNING_IN_LOCALHOST: import.meta.env.DEV,
    });

    const viteEnv = partiallyParsedEnv.VITE_ENV || 'development';

    const baseUrlForPublicLinks =
      viteEnv === 'development'
        ? 'http://localhost:1420'
        : viteEnv === 'staging'
          ? 'https://dev.lilbird.io'
          : 'https://lilbird.io';

    const enableDevTools =
      rawEnv.VITE_ENABLE_DEVTOOLS === 'true' ||
      (viteEnv === 'development' && rawEnv.VITE_ENABLE_DEVTOOLS !== 'false');

    const parsedEnv = envSchema.parse({
      ...rawEnv,
      IS_RUNNING_IN_LOCALHOST: import.meta.env.DEV,
      BASE_URL_FOR_PUBLIC_LINKS: baseUrlForPublicLinks,
      ENABLE_DEVTOOLS: enableDevTools,
    });

    return parsedEnv;
  } catch (error) {
    logError(error, '❌ Invalid environment variables');
    throw new Error('Invalid environment configuration');
  }
}

export function getEnvVar<K extends keyof Environment>(key: K): Environment[K] {
  return env[key];
}

const env = validateEnv();

export default env;
