import { createFileRoute } from '@tanstack/react-router';
import { useEffect, useRef, useState } from 'react';
import { z } from 'zod';

const redirectParamsSchema = z.object({
  code: z.string().catch(''),
  state: z.string().catch(''),
  error: z.string().optional(),
  error_description: z.string().optional(),
});

export const Route = createFileRoute('/auth-callback/desktop')({
  component: RouteComponent,
  validateSearch: (search) => redirectParamsSchema.parse(search),
});

function RouteComponent() {
  const params = Route.useSearch();
  const isPromptTriggered = useRef(false);
  const [showCloseWindowPrompt, setShowCloseWindowPrompt] = useState(false);

  useEffect(() => {
    if (isPromptTriggered.current) return;
    isPromptTriggered.current = true;
    const state = params.state;
    const lastHyphenIndex = state.lastIndexOf('-');
    const protocolSchema =
      lastHyphenIndex !== -1 ? state.substring(0, lastHyphenIndex) : null;
    window.open(
      `${protocolSchema}://auth-callback?${new URLSearchParams(params)}`,
      '_self'
    );
    setShowCloseWindowPrompt(true);
    window.close();
  }, [params, setShowCloseWindowPrompt]);
  if (!showCloseWindowPrompt) return <div>Authenticating...</div>;
  return <div>You can close this window</div>;
}
