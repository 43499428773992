import { atomWithStorage } from 'jotai/utils';
import { atomWithQuery } from 'jotai-tanstack-query';

import { isAuthValidQueryOptions } from '@/query/auth';
import { getLocalStorageItem } from '@/utils/localStorage';

const ACCESS_TOKEN = 'access_token';

export const accessTokenAtom = atomWithStorage<string | null>(
  ACCESS_TOKEN,
  getLocalStorageItem(ACCESS_TOKEN)
);

export const isAuthValidQueryAtom = atomWithQuery(() =>
  isAuthValidQueryOptions()
);
